var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import { staticPagesList } from "../../store/static.pages.store";
export default function AgreementMenu(props) {
    return (_jsx("div", __assign({ className: "card" }, { children: _jsx("div", __assign({ className: "card-body p-0" }, { children: _jsxs("ul", __assign({ className: "menu", style: { padding: innerWidth <= 968 ? "10px" : "0px" } }, { children: [staticPagesList.map(function (page, index) {
                        return _jsx("li", __assign({ className: "".concat(props.slug == page.slug ? 'active' : '') }, { children: _jsx(Link, __assign({ to: "/sozlesmeler/" + page.slug }, { children: _jsx("span", { children: page.title }, void 0) }), void 0) }), void 0);
                    }), _jsx("li", __assign({ className: "".concat(props.slug == 'sorumlu-bahis' ? 'active' : '') }, { children: _jsx(Link, __assign({ to: "/sozlesmeler/sorumlu-bahis" }, { children: _jsx("span", { children: "Sorumlu Bahis" }, void 0) }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0));
}
