var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { linkify, useForceUpdate } from "../../../lib/misc.functions";
import { askToDelete, askToReport, commentUpdateSubject, getEventComments, likePost } from "../../../services/sosyoley.services";
import DefaultAvatar from "../../../assets/img/avatar.png";
import Heart from "../../../assets/feather/heart.svg";
import HeartFill from "../../../assets/feather/heart-fill.svg";
import TrashRed from "../../../assets/feather/trash-2-red.svg";
import Flag from "../../../assets/feather/flag.svg";
import { env } from "../../../definitions/global.vars";
import iconComment from "../../../assets/feather/message-circle.svg";
import cantComment from "../../../assets/feather/x-circle.svg";
import { member } from "../../../store/login.store";
var CommetLoadMoreLock = false;
var scrollListenerTimer;
var CommetPage = 0;
export function EventComments(props) {
    var forceUpdate = useForceUpdate();
    var event = props.event;
    var setPostCommentModal = props.modalState;
    var _a = useState(null), selectedComment = _a[0], setSelectedComment = _a[1];
    useEffect(function () {
        CommetPage = 0;
        var eventDetailWrapper = document.getElementById('event-content');
        if (eventDetailWrapper) {
            eventDetailWrapper.addEventListener('scroll', onScroll);
        }
        return function () {
            clearTimeout(scrollListenerTimer);
            var eventDetailWrapper = document.getElementById('event-content');
            if (eventDetailWrapper) {
                eventDetailWrapper.removeEventListener('scroll', onScroll);
            }
        };
    }, []);
    var onScroll = function (e) {
        // console.log(e.target.scrollHeight, e.target.offsetHeight, e.target.scrollTop)
        if (!CommetLoadMoreLock && event.comments.length >= 10 && (e.target.scrollHeight - 50 < e.target.offsetHeight + e.target.scrollTop)) {
            if (!CommetLoadMoreLock) {
                CommetLoadMoreLock = true;
                CommetPage += 1;
                getEventComments(event.eventId, CommetPage).then(function (comments) {
                    // console.log("done" , comments)
                    event.comments = event.comments.concat(comments);
                    CommetLoadMoreLock = false;
                    forceUpdate();
                });
            }
        }
    };
    useEffect(function () {
        var postIds = [];
        if (event.comments && event.comments.length > 0) {
            postIds = event.comments.map(function (p) { return p.post_id; });
        }
        // retry();
        var commentUpdateSubject_subscription = commentUpdateSubject.subscribe(function (eventId) {
            if (typeof eventId !== 'object') {
                if (Number(event.eventId) === eventId || postIds.includes(eventId)) {
                    forceUpdate();
                }
            }
        });
        return function () {
            commentUpdateSubject_subscription.unsubscribe();
        };
    }, []);
    if (event.comments) {
        if (event.comments.length > 0) {
            return (_jsx(React.Fragment, { children: event.comments.map(function (el, key) {
                    var profileLink = "<a href=\"/sosyoley/profil/".concat(el.member_id, "/tumu\" class=\"nickname\">@").concat(el.member.nick_name, ": </a>");
                    var commentHtml = profileLink + linkify(el.comment);
                    return (_jsxs("div", __assign({ className: "post ", onClick: function () {
                            setSelectedComment(el);
                        } }, { children: [_jsxs("div", __assign({ className: "d-flex" }, { children: [_jsx("img", { className: "avatar", src: el.member.photo ? env.sosyoley_api + el.member.photo : DefaultAvatar }, void 0), _jsx("div", { className: "comment", dangerouslySetInnerHTML: { __html: commentHtml } }, void 0)] }), void 0), selectedComment && selectedComment.create_date == el.create_date && selectedComment.member_id == el.member_id && _jsxs("div", __assign({ style: { borderBottom: "0" }, className: "row actions mx-4 my-1" }, { children: [_jsx("div", __assign({ className: "col-2" }, { children: (el.comment_perm) ?
                                            (_jsxs(_Fragment, { children: [_jsx("img", { src: iconComment, width: 16, onClick: function () {
                                                            setPostCommentModal(el);
                                                        } }, void 0), _jsx("i", { children: el.comment_count }, void 0)] }, void 0))
                                            : (_jsx("img", { src: cantComment, width: 16 }, void 0)) }), void 0), _jsxs("div", __assign({ className: "col-2" }, { children: [_jsx("img", { src: el.is_liked && el.is_liked[0] === 1 ? HeartFill : Heart, width: 16, onClick: function () {
                                                    likePost(el);
                                                } }, void 0), _jsx("i", { children: el.like_count }, void 0)] }), void 0), _jsx("div", __assign({ className: "col-2" }, { children: _jsx("img", { src: Flag, width: 16, onClick: function () {
                                                askToReport(el);
                                            } }, void 0) }), void 0), _jsx("div", __assign({ className: "col-2" }, { children: member && Number(el.member_id) === Number(member.id) ?
                                            _jsx("img", { src: TrashRed, width: 16, onClick: function () {
                                                    askToDelete(el, event);
                                                } }, void 0) : _jsx(_Fragment, {}, void 0) }), void 0), _jsx("div", __assign({ className: "col-2" }, { children: _jsx("button", __assign({ onClick: function () {
                                                var txtComment = document.getElementById('id_txtComment');
                                                if (txtComment) {
                                                    // @ts-ignore
                                                    txtComment.value = txtComment.value !== '' ? txtComment.value + ' @' + el.member.nick_name : '@' + el.member.nick_name;
                                                    txtComment.focus();
                                                }
                                            }, className: "btn btn-small btn-outline-primary" }, { children: "Yan\u0131tla" }), void 0) }), void 0)] }), void 0)] }), key));
                }) }, void 0));
        }
        else {
            return (_jsx("div", __assign({ className: "text-center m-3" }, { children: "Hen\u00FCz hi\u00E7 yorum yok" }), void 0));
        }
    }
    return _jsx("div", __assign({ className: "text-center m-3" }, { children: "Y\u00FCkleniyor.." }), void 0);
}
