var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { FinancalTransactionsApi } from "../../subpages/financialTransactions/financial.transactions.api";
import { Storage } from "../../../../lib/localstorege.service";
import { modalServiceSubject } from "../../../../components/modal/modal";
import iconPlus from "../../../../assets/feather/plus-circle.svg";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { customStyles } from "../../../../definitions/constants/modal.constant";
import { currencyFormat, useForceUpdate } from "../../../../lib/misc.functions";
import { member } from "../../../../store/login.store";
var BankTransferAddWidget = function (props) {
    // console.log(props)
    var token = Storage.get('token');
    var _a = useState([]), myBankAccountData = _a[0], setMyBankAccountData = _a[1];
    var _b = useState(false), isAdded = _b[0], setIsAdded = _b[1];
    var _c = React.useState(false), modalIsOpen = _c[0], setIsModalOpen = _c[1];
    var forceUpdate = useForceUpdate();
    function openModal() {
        setIsModalOpen(true);
    }
    function closeModal() {
        setIsModalOpen(false);
    }
    var _d = useForm(), register = _d.register, handleSubmit = _d.handleSubmit, errors = _d.formState.errors, reset = _d.reset;
    useEffect(function () {
        var data = { token: token };
        FinancalTransactionsApi.myBankAccounts(data).then(function (result) {
            if (result.status) {
                setMyBankAccountData(result.data);
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    }, [isAdded]);
    var withdrawMoney = function (data) {
        console.log(data);
        if (data && data.iban && data.iban !== '') {
            if (Number(data.amount) >= 10) {
                var formData = {
                    token: token,
                    amount: data.amount,
                    account_id: data.iban
                };
                FinancalTransactionsApi.withdrawAdd(formData).then(function (result) {
                    if (result.status) {
                        modalServiceSubject.next({
                            content: "Para çekme talebiniz işleme alındı.",
                            confirm: {
                                cancel: {
                                    label: 'Tamam'
                                }
                            },
                            timeout: 0
                        });
                        reset({ amount: '', iban: '' });
                        props.setIsTransfer(!props.isTransfer);
                    }
                    else {
                        modalServiceSubject.next({
                            title: "Hata!",
                            content: result.message,
                            confirm: {
                                cancel: { label: 'Tamam' }
                            },
                            timeout: 0
                        });
                    }
                }).catch(function (e) {
                    console.log(">>>", e);
                    modalServiceSubject.next({
                        title: "Hata!",
                        content: "Teknik bir sorun oluştu, Lütfen daha sonra tekrar deneyiniz ya da müşteri hizmetleri birimimize başvurunuz.",
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0
                    });
                });
            }
            else {
                modalServiceSubject.next({
                    title: "Hata!",
                    content: "En az 10 TL için talep verebilirsiniz.",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        }
        else {
            modalServiceSubject.next({
                title: "Hata!",
                content: "Lütfen hesap seçiniz..",
                confirm: {
                    cancel: { label: 'Tamam' }
                },
                timeout: 0
            });
        }
    };
    return (_jsxs(_Fragment, { children: [(myBankAccountData.length == 0) ?
                (_jsxs("div", { children: [_jsx("div", __assign({ className: "mb-2" }, { children: _jsx("span", __assign({ className: "text-danger" }, { children: "Kay\u0131tl\u0131 banka hesab\u0131n\u0131z yok." }), void 0) }), void 0), _jsx("button", __assign({ className: "btn btn-primary justify-content-center", onClick: function () { return openModal(); } }, { children: "Banka Hesab\u0131 Ekle" }), void 0)] }, void 0))
                : (_jsx("div", __assign({ className: "add-account-widget table" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(withdrawMoney) }, { children: [_jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsx("span", { children: "Sadece ad\u0131n\u0131za a\u00E7\u0131lm\u0131\u015F vadesiz mevduat hesaplar\u0131na yada do\u011Frulanm\u0131\u015F bir \u0130ninal hesab\u0131n\u0131za para aktarabilirsiniz. T\u00FCrkiye \u0130\u015F Bankas\u0131'na en az 20 TL di\u011Fer bankalara en az 10 TL i\u00E7in talep verebilirsiniz. T\u00FCm sanal bayilerde, ATM karts\u0131z i\u015Flem ile yap\u0131lan para g\u00F6nderimlerinde yat\u0131r\u0131lan tutar \"\u00C7ekilemez Tutar\" olarak g\u00F6r\u00FCnecektir.\u00C7ekilemez tutar ile sadece \u015Fans oyunlar\u0131 oynayabilirsiniz." }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsxs("select", __assign({ className: "col mt-3" }, register('iban', { required: true }), { children: [_jsx("option", __assign({ value: "" }, { children: "Hesap Se\u00E7iniz" }), void 0), myBankAccountData ? myBankAccountData.map(function (account, key) {
                                                    return (_jsx("option", __assign({ value: account.id }, { children: account.display_label }), key));
                                                }) : null] }), void 0), _jsxs("a", __assign({ style: { marginTop: '-5px', cursor: 'pointer', fontSize: '12px', fontStyle: 'italic' }, onClick: function () { return openModal(); } }, { children: ["Farkl\u0131 bir banka hesab\u0131 ekle", _jsx("img", { src: iconPlus, width: 16, className: "ml-2", onClick: function () { return openModal(); } }, void 0)] }), void 0), errors.iban && errors.iban.type === "required" &&
                                            _jsx("span", __assign({ className: "d-block text-danger" }, { children: "L\u00FCtfen bir hesap se\u00E7iniz." }), void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-12 mt-3" }, { children: _jsxs("label", __assign({ className: "fw-bold" }, { children: ["Hesap Bakiyesi: ", currencyFormat(Number(member.debit_balance)), "\u20BA"] }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "row" }, { children: _jsxs("div", __assign({ className: "col-12 " }, { children: [_jsx("label", __assign({ className: "d-block fw-bold" }, { children: "\u00C7ekilecek Tutar:" }), void 0), _jsxs("div", __assign({ className: "d-flex" }, { children: [_jsx("input", __assign({ id: "inp_withdraw_amount", type: "number" }, register('amount', { required: true }), { style: { marginRight: 5, width: '100%', borderRadius: '5px', padding: '7px 10px', border: '1px solid var(--color-bg-medium)' } }), void 0), _jsx("button", __assign({ className: "btn btn-primary" }, { children: "Para \u00C7ek" }), void 0)] }), void 0), errors.amount && errors.amount.type === "required" &&
                                            _jsx("span", __assign({ className: "text-danger" }, { children: "Ge\u00E7erli bir tutar giriniz" }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)), _jsx(AddNewIban, { openModal: openModal, closeModal: closeModal, modalIsOpen: modalIsOpen, setIsModalOpen: setIsModalOpen, isAdded: isAdded, setIsAdded: setIsAdded }, void 0)] }, void 0));
};
export default BankTransferAddWidget;
export function AddNewIban(props) {
    var isAdded = props.isAdded;
    var setIsAdded = props.setIsAdded;
    var closeModal = props.closeModal;
    var setIsModalOpen = props.setIsModalOpen;
    var modalIsOpen = props.modalIsOpen;
    var token = Storage.get('token');
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, errors = _a.formState.errors, reset = _a.reset;
    var callRegisterApi = function (data) {
        data.token = token;
        data.iban = data.iban.replace(/ /g, '');
        if (!data.iban.startsWith('TR')) {
            data.iban = 'TR' + data.iban;
        }
        if (data.iban.endsWith(' ')) {
            data.iban = data.iban.replace(' ', '');
        }
        FinancalTransactionsApi.addMyBankAccounts(data).then(function (result) {
            if (result.status) {
                setIsModalOpen(false);
                modalServiceSubject.next({
                    title: "Başarılı!",
                    content: "Kayıt başarıyla gerçekleşmiştir.",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
                setIsAdded(!isAdded);
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    var onSubmit = function (data) {
        callRegisterApi(data);
    };
    return (_jsxs(Modal, __assign({ isOpen: modalIsOpen, onRequestClose: closeModal, contentLabel: "", style: customStyles }, { children: [_jsx("div", __assign({ className: "modal-header" }, { children: _jsx("h2", { children: "Banka Hesab\u0131 Ekle" }, void 0) }), void 0), _jsx("div", __assign({ className: "modal-body" }, { children: _jsx("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("p", { children: "L\u00FCtfen ad\u0131n\u0131za a\u00E7\u0131lm\u0131\u015F bir banka hesab\u0131na ait iban giriniz." }, void 0), _jsxs("div", __assign({ style: { display: 'flex', border: '1px solid #ccc', width: '100%', padding: '5px', borderRadius: '5px' } }, { children: [_jsx("div", { children: "TR" }, void 0), " ", _jsx("input", __assign({ style: { width: '100%', border: 0 } }, register('iban', { required: true })), void 0)] }), void 0), errors.iban && errors.iban.type === "required" &&
                                        _jsx("span", { children: "IBAN No gereklidir." }, void 0)] }), void 0), _jsxs("div", __assign({ className: "col-5 mt-2 d-flex" }, { children: [_jsx("a", __assign({ className: "btn btn-default", onClick: function () { setIsModalOpen(false); } }, { children: "Vazge\u00E7" }), void 0), _jsx("button", __assign({ className: "btn btn-primary" }, { children: "Hesap Ekle" }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0)] }), void 0));
}
