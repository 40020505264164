var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Collapsible from "react-collapsible";
import { popularCompetition, sportsbookFilter } from "../../../store/sportsbook.store";
import { setFilterCompetitions } from "../../../services/sportsbook.services";
import { useForceUpdate } from "../../../lib/misc.functions";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import fire from '../../../assets/feather/fire.png';
export function SideCompetitionList(props) {
    var competitionGroups = props.competitionGroups;
    var eventType = props.eventType;
    var sportSlug = props.sportSlug;
    var forceUpdate = useForceUpdate();
    var navigate = useNavigate();
    var params = useParams();
    var setFilter = function (competitionId, sportSlug, eventType) {
        setFilterCompetitions(competitionId);
        forceUpdate();
        navigate("/iddaa/" + sportSlug + "/" + eventType);
    };
    if (!competitionGroups) {
        return _jsx(React.Fragment, {}, void 0);
    }
    var popularCompetitions = popularCompetition.filter(function (el) { return el.sport === sportSlug; });
    return (_jsxs(React.Fragment, { children: [popularCompetitions.length > 0 ? _jsxs(_Fragment, { children: [_jsx(Collapsible, __assign({ open: true, trigger: _jsxs(_Fragment, { children: [_jsx("div", { children: "Pop\u00FCler Ligler" }, void 0), _jsxs("span", { children: ["+", popularCompetitions.length] }, void 0)] }, void 0) }, { children: popularCompetitions.map(function (popularMartche, key) {
                            if (popularMartche.name.includes('Düello') && params.eventType === 'canli') {
                                return null;
                            }
                            else {
                                return (_jsxs("a", __assign({ className: "cursor-pointer", onClick: function () {
                                        setFilter(popularMartche.id, popularMartche.sport, eventType);
                                    } }, { children: [_jsx("input", { type: "checkbox", checked: popularMartche.id.split(',').every(function (e) { return sportsbookFilter.filterCompetition.indexOf(e) > -1; }), onChange: function () {
                                            } }, void 0), " ", popularMartche.name, "\u00A0", popularMartche.name === ('UEFA Süper Kupa') ? _jsx("div", __assign({ className: 'flames ' }, { children: _jsx("img", { src: fire, className: 'm-auto', height: 18, width: 18 }, void 0) }), void 0) : null, popularMartche.name.includes('Düello') ? _jsxs(_Fragment, { children: [_jsx("span", __assign({ className: 'new-badge blink_me mx-0 my-auto', style: { color: '#fff' }, id: 'newBadgeBtn' }, { children: "YEN\u0130" }), void 0), "  "] }, void 0) : null] }), "popularmartche2" + key));
                            }
                        }) }), void 0), _jsx("hr", {}, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0), competitionGroups.map(function (item, key) {
                // console.log(item)
                return (_jsx("div", { children: _jsx(Collapsible, __assign({ trigger: _jsxs(_Fragment, { children: [_jsx("img", { src: "https://cdn.oley.com/images/country_icons_circle/".concat(item.competitionGroupName === "IIIskoçya" ? 'Scotland' : item.countryName, ".png") }, void 0), _jsx("div", { children: item.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, void 0), _jsxs("span", { children: ["+", item.count] }, void 0)] }, void 0) }, { children: Object.entries(item.competitions).map(function (comp, key) {
                            return (_jsxs("a", __assign({ className: "cursor-pointer", onClick: function () {
                                    var competitionIds = comp[1].map(function (item) { return item.competitionId; }).filter(function (value, index, current_value) { return current_value.indexOf(value) === index; }).join(',');
                                    setFilter(competitionIds, item.sportSlug, eventType);
                                } }, { children: [_jsx("input", { type: "checkbox", checked: sportsbookFilter.filterCompetition.indexOf(comp[1][0].competitionId) !== -1, onChange: function () {
                                        } }, void 0), _jsx("div", { children: comp[1][0].competitionName.replace('UEFA Şampiyonlar Ligi Elemeler', 'UEFA Şampiyonlar Ligi Elemeleri').replace('UEFA Avrupa Ligi, Elemeler', 'UEFA Avrupa Ligi Elemeleri') }, void 0), _jsxs("span", { children: ["+", comp[1].length] }, void 0)] }), "iddiamarketmenu2event".concat(key)));
                        }) }), "eventSporMenu2".concat(key)) }, sportSlug + '_' + key));
            })] }, void 0));
}
