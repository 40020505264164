var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { initialLeftMenu, sportsMenu } from "../../../lib/misc.functions";
import { useAuthContext } from "../../../store/login.store";
import { useNavigate } from "react-router-dom";
import { Storage } from "../../../lib/localstorege.service";
import { betSlipVisibleSubject } from "../../../store/betslip.store";
import { ApiService } from "../../../lib/http.service";
import { env } from "../../../definitions/global.vars";
function RockPaperScissors() {
    var iframeContainerRef = useRef(null);
    var isAuthenticated = useAuthContext().isAuthenticated;
    var navigate = useNavigate();
    var token = Storage.get('token');
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
        if (isAuthenticated) {
            var api = new ApiService();
            api
                .start("post", env.accounting_api + "/games/ert/vendor/auth?game_code=taskagitmakas", { token: token }, true)
                .then(function (res) {
                if (res && res.status && iframeContainerRef.current) {
                    iframeContainerRef.current.src = res.data.url;
                }
            });
        }
    }, [iframeContainerRef.current]);
    if (isAuthenticated) {
        return (_jsx("div", { children: _jsx("iframe", { ref: iframeContainerRef, className: "iframeContainer" }, void 0) }, void 0));
    }
    else {
        return (_jsxs("div", __assign({ className: 'card', style: { marginTop: innerWidth <= 968 ? "25px" : "0px" } }, { children: [_jsx("h2", __assign({ className: "card-title my-5 text-center" }, { children: "Ta\u015F Ka\u011F\u0131t Makas oynamak i\u00E7in giri\u015F yapmal\u0131s\u0131n\u0131z!" }), void 0), _jsx("div", __assign({ className: "card-body d-flex justify-content-center mb-5" }, { children: _jsx("button", __assign({ className: 'btn btn-warning btnLogin', onClick: function () { return navigate('/giris-yap'); } }, { children: "G\u0130R\u0130\u015E YAP" }), void 0) }), void 0)] }), void 0));
    }
}
export default RockPaperScissors;
