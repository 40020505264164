var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { ApiService } from "../../lib/http.service";
import { env } from "../../definitions/global.vars";
import { betSlipVisiblity } from "../../shared/betslip/betslip";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { staticPagesList } from "../../store/static.pages.store";
import { Storage } from "../../lib/localstorege.service";
export function FlatPage() {
    var params = useParams();
    var _a = useState(null), page = _a[0], setPage = _a[1];
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        if (innerWidth > 968) {
            betSlipVisibleSubject.next(true);
        }
    }, []);
    useEffect(function () {
        var staticPagesData = staticPagesList.find(function (x) { return x.slug == params.slug; })
            || Storage.get('staticPagesList').find(function (x) { return x.slug == params.slug; });
        if (staticPagesData) {
            setPage(staticPagesData);
        }
        else {
            var api = new ApiService();
            api.start('get', env.misc_api + '/misc/api/list-flatpage/?slug=' + params.slug, {}, false)
                .then(function (res) {
                if (res.status) {
                    setPage(res.data[0]);
                }
            });
        }
    }, []);
    if (!page) {
        return (_jsx(_Fragment, {}, void 0));
    }
    return _jsx("div", __assign({ className: "announcement", style: { width: (betSlipVisiblity ? 'calc(100% - 300px)' : '100%') } }, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: page.title }, void 0) }), void 0), _jsx("div", { className: "card-body", style: { minHeight: '500px' }, dangerouslySetInnerHTML: { __html: page.content } }, void 0)] }), void 0) }), void 0);
}
