var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { currencyFormat, dateTimeStringFormat, useForceUpdate } from "../../../lib/misc.functions";
import { tjkBetSlipActionSubject, tjkBetSlipOptions, tjkBetSlipVisibleSubject, tjkComplateBettingTypes, tjkSortedBetTypes } from "../tjk.store";
import "./tjk.betslip.css";
import _ from "lodash";
import { member } from "../../../store/login.store";
import Trash from "../../../assets/feather/trash-2.svg";
import Save from "../../../assets/feather/save.svg";
import { addToTJKBetSlip, clearTjkBetSlip, getbetCount, PlayTjkCoupon, setTjkBetSlipMaxAmount, setTjkBetSlipMultiply, setTjkComplete, setTjkUsePointBalance } from "../tjk.betslip.service";
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from "array-move";
import { modalServiceSubject } from "../../../components/modal/modal";
import { HorseSortable } from "../widgets/horse-sortable";
import { MultipleColumnHorselist } from "../widgets/multiple-column-horselist";
import { SingleColumnHorselist } from "../widgets/single-column-horselist";
export var tjkBetslipStore = [];
var width = window.innerWidth;
var tjkBetslipTimer = null;
export var betSlipToggle = null;
export function TjkBetSlip() {
    var width = window.innerWidth;
    var isMobile = width < 986 ? true : false;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var _a = useState([]), itemOrder = _a[0], setItemOrder = _a[1];
    useEffect(function () {
        betSlipToggle = width < 986 ? false : true;
    }, []);
    var onSortEnd = function (e) {
        // console.log(e)
        var oldIndex = e.oldIndex, newIndex = e.newIndex;
        var newArray = arrayMoveImmutable(itemOrder, oldIndex, newIndex);
        var _loop_1 = function (item) {
            var exIndex = tjkBetslipStore.findIndex(function (el) { return tjkBetSlipOptions.bettingType.id === el.bettingType.id &&
                el.bettingSubType === tjkBetSlipOptions.bettingSubType &&
                el.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
                Number(el.horse.no) === Number(item.split(' - ')[0]); });
            console.log(exIndex, tjkBetslipStore[exIndex], newArray.indexOf(item));
            Object.assign(tjkBetslipStore, arrayMoveImmutable(tjkBetslipStore, exIndex, newArray.indexOf(item)));
        };
        // console.log(newArray)
        for (var _i = 0, newArray_1 = newArray; _i < newArray_1.length; _i++) {
            var item = newArray_1[_i];
            _loop_1(item);
        }
        setItemOrder(newArray);
    };
    useEffect(function () {
        var tjkBetSlipVisibleSubject_subject = tjkBetSlipVisibleSubject.subscribe(function (res) {
            if (res === 'close') {
                betSlipToggle = false;
            }
            else {
                betSlipToggle = !betSlipToggle;
            }
            forceUpdate();
        });
        return function () {
            betSlipToggle = false;
            tjkBetSlipVisibleSubject_subject.unsubscribe();
        };
    }, []);
    useEffect(function () {
        var slipItem = tjkBetslipStore.filter(function (el) {
            if (el.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
                el.bettingType.id === tjkBetSlipOptions.bettingType.id &&
                el.bettingSubType === tjkBetSlipOptions.bettingSubType) {
                return el;
            }
        });
        setItemOrder(slipItem.map(function (i) { return i.horse.no + ' - ' + i.horse.name; }));
    }, [tjkBetSlipOptions.bettingType, tjkBetSlipOptions.bettingSubType, tjkBetSlipOptions.hippodrome]);
    useEffect(function () {
        var subscription = tjkBetSlipActionSubject.subscribe(function () {
            // console.log(tjkBetslipStore)
            // fixme burası performans için değiştirilmeli
            var slipItem = tjkBetslipStore.filter(function (el) {
                if (el.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
                    el.bettingType.id === tjkBetSlipOptions.bettingType.id &&
                    el.bettingSubType === tjkBetSlipOptions.bettingSubType) {
                    return el;
                }
            });
            setItemOrder(slipItem.map(function (i) { return i.horse.no + ' - ' + i.horse.name; }));
            forceUpdate();
        });
        return function () {
            document.body.style.overflow = 'unset';
            subscription.unsubscribe();
        };
    }, []);
    if (!tjkBetSlipOptions.hippodrome || !tjkBetSlipOptions.bettingType || !tjkBetSlipOptions.bettingSubType)
        return (_jsx(_Fragment, {}, void 0));
    var betCount = getbetCount();
    var totalAmount = tjkBetSlipOptions.betSlipMultiply * betCount * tjkBetSlipOptions.bettingType.price;
    var betSlipMaxMultiply = betCount > 0 ? Math.floor(5000 / betCount) : 5000;
    var multiplyChoices = [];
    if (betSlipMaxMultiply > 200) {
        multiplyChoices = _.range(1, 101);
        var mod = betSlipMaxMultiply % 50;
        multiplyChoices.push.apply(multiplyChoices, _.range(200, betSlipMaxMultiply, 50));
        if (mod > 0) {
            multiplyChoices.push((multiplyChoices[multiplyChoices.length - 1] + mod));
        }
    }
    else {
        multiplyChoices = _.range(1, betSlipMaxMultiply + 1);
    }
    if (totalAmount > 5000) {
        tjkBetSlipOptions.errorMessage = 'Toplam bahis tutarı 5000 TL\'yi geçemez.';
        totalAmount = tjkBetSlipOptions.betSlipMultiply * betCount * tjkBetSlipOptions.bettingType.price;
    }
    else if (tjkBetSlipOptions.errorMessage === 'Toplam bahis tutarı 5000 TL\'yi geçemez.') {
        tjkBetSlipOptions.errorMessage = null;
    }
    var addFromSelect = function (e) {
        var runObject = tjkBetSlipOptions.hippodrome.runs.find(function (r) { return r.no === tjkBetSlipOptions.bettingSubType; });
        addToTJKBetSlip(1, runObject, runObject.horses.find(function (h) { return Number(h.no) === Number(e.split('-')[0]); }));
        var i = itemOrder.indexOf(e);
        if (i !== -1) {
            itemOrder.splice(i, 1);
        }
        else {
            itemOrder.push(e);
        }
        setItemOrder(__spreadArray([], itemOrder, true));
    };
    var playCoupon = function (save) {
        if (save === void 0) { save = false; }
        tjkBetSlipOptions.inProgress = true;
        PlayTjkCoupon(save).then(function (result) {
            console.log(result);
            if (result) {
                if (result.status === true && save === false) {
                    clearTjkBetSlip();
                    modalServiceSubject.next({
                        title: 'Kuponunuz Oynandı',
                        content: "Kuponunuz başarıyla oynandı. Kuponunuzun durumunu kuponlarım sayfasından takip edebilirsiniz.",
                        confirm: {
                            sure: {
                                label: 'TJK Kuponlarım',
                                action: function () {
                                    navigate('/hesabim/tjk/devam-edenler');
                                }
                            },
                            cancel: {
                                label: 'Yeni Kupon Oyna'
                            }
                        }
                    });
                }
                else if (result.status && save === true) {
                    modalServiceSubject.next({
                        title: 'Kuponunuz Kaydedildi',
                        content: "Kuponunuz başarıyla Kaydedildi. Kuponunuzu kuponlarım sayfasında görüntüleyebilirsiniz",
                        confirm: {
                            sure: {
                                label: 'Kayıtlı Kuponlarım',
                                action: function () {
                                    navigate('/hesabim/tjk/kayitli');
                                }
                            },
                            cancel: {
                                label: 'Yeni Kupon Oyna'
                            }
                        }
                    });
                }
                else {
                    // tjkBetSlipOptions.errorMessage = typeof(result.message) === 'object' ? JSON.stringify(result.message): result.message;
                    var error_description = null;
                    var error_message = null;
                    if (result.data && result.data.error) {
                        error_description = result.data.error.error_description;
                        error_message = result.data.error.error_message;
                    }
                    var sure = null;
                    if (typeof (result.message) === 'string' && result.message.indexOf('Oturum') !== -1) {
                        sure = {
                            label: 'Giriş Yap',
                            action: function () {
                                navigate('/giris-yap');
                            }
                        };
                    }
                    if (error_message === 'TIMEOUT') {
                        sure = {
                            label: 'TJK Kuponlarım',
                            action: function () {
                                navigate('/hesabim/tjk/devam-edenler');
                            }
                        };
                    }
                    modalServiceSubject.next({
                        title: 'Hata',
                        content: error_description || (typeof (result.message) === 'object' ? JSON.stringify(result.message) : result.message),
                        confirm: {
                            sure: sure,
                            cancel: {
                                label: 'Tamam',
                            }
                        }
                    });
                    forceUpdate();
                }
            }
            tjkBetSlipOptions.inProgress = false;
            forceUpdate();
        });
        forceUpdate();
    };
    var confirmCoupon = function () {
        clearTimeout(tjkBetslipTimer);
        if (betCount === 0) {
            tjkBetSlipOptions.errorMessage = 'Kuponunuzda oynanacak bir yarış bulunmamaktadır.';
        }
        else if ((tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount) > 5000) {
            tjkBetSlipOptions.errorMessage = 'Kupon tutarı 5000 TL\'den fazla olamaz.';
        }
        else if (tjkBetSlipOptions.bettingType.id === 18 && (tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount) < 2) {
            tjkBetSlipOptions.errorMessage = 'Kupon tutarı 2 TL\'den az olamaz.';
        }
        else if ((tjkBetSlipOptions.usePointBalance ? Number(member.debit_balance) + (member.point_balance / 100) : member.debit_balance) < (tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount)) {
            tjkBetSlipOptions.errorMessage = 'Bakiyeniz yetersiz.';
        }
        if (tjkBetSlipOptions.errorMessage === null) {
            var slipItem_1 = tjkBetslipStore.filter(function (el) {
                if (el.hippodrome.id === tjkBetSlipOptions.hippodrome.id &&
                    el.bettingType.id === tjkBetSlipOptions.bettingType.id &&
                    el.bettingSubType === tjkBetSlipOptions.bettingSubType) {
                    return el;
                }
            });
            var uniqueColumn_1 = __spreadArray([], Array.from(new Set(slipItem_1.map(function (i) { return i.column; }))), true);
            modalServiceSubject.next({
                title: 'Bahis Detayı',
                width: '960px',
                content: "\n        <table class=\"table table-striped tjk-nowrap table-bordered\">\n            <tbody>\n                <tr>\n                    <th class=\"text-left\">Bahis T\u00FCr\u00FC</th>\n                    <td class=\"text-left\">".concat(tjkBetSlipOptions.bettingType.name, "</td>\n                    <th></th>\n                    <td></td>\n                </tr>\n                <tr>\n                    <th class=\"text-left\">Ko\u015Fu Tarihi</th>\n                    <td class=\"text-left\">").concat(dateTimeStringFormat(tjkBetSlipOptions.hippodrome.date, true), "</td>\n                    <th class=\"text-left\">Hipodrom</th>\n                    <td class=\"text-left\">").concat(tjkBetSlipOptions.hippodrome.location, "</td>\n                </tr>\n                <tr>\n                    <th class=\"text-left\">Ko\u015Fu</th>\n                    <td class=\"text-left\">").concat(tjkBetSlipOptions.bettingSubType, "</td>\n                    <th class=\"text-left\">Bahis Say\u0131s\u0131</th>\n                    <td class=\"text-left\">").concat(betCount, "</td>\n                </tr>\n                <tr>\n                    <th class=\"text-left\">Misli</th>\n                    <td class=\"text-left\">").concat(tjkBetSlipOptions.betSlipMultiply, "</td>\n                    <th class=\"text-left\">Tutar</th>\n                    <td class=\"text-left\">").concat(currencyFormat(totalAmount), "\u20BA</td>\n                </tr>\n            </tbody>\n        </table>\n        \n        <div class=\"row agf-tables\" style=\"width:auto; margin-top: 25px;\">\n          ").concat(uniqueColumn_1.map(function (e) {
                    return "<div class=\"col-2\" style=\"width: calc(100% / ".concat(uniqueColumn_1.length, ");flex: unset;max-width: unset;\">\n            <div class=\"run-no\">").concat(tjkBetSlipOptions.bettingType.columnLabel === 'Koşu' ? tjkBetSlipOptions.bettingSubType : e, ". ").concat(tjkBetSlipOptions.bettingType.columnLabel, "</div>\n<!--            <div class=\"horse-header d-flex\">-->\n<!--              <div class=\"text-center\" style=\"width: 35px;\">#</div>-->\n<!--              <div class=\"text-left\" style=\"width: calc(100% - 35px);\">At</div>-->\n<!--            </div>-->\n            ").concat(_.orderBy(slipItem_1, [function (el) { if (el.horse === 's') {
                            return 0;
                        }
                        else {
                            return 1;
                        } }]).filter(function (h) { return h.column === e; }).map(function (item, key) {
                        return "<div class=\"horse-no d-flex\">\n                <div class=\"text-center\" style=\"width: 15px;\">".concat(tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) ? (key + 1) + '.At' : (item.horse === 's' ? 'S' : item.horse.no), "</div>\n                <div class=\"text-left\" style=\"width: calc(100% - 15px);\">\n                  ").concat(tjkSortedBetTypes.includes(tjkBetSlipOptions.bettingType.id) ? item.horse.no : '', " ").concat(item.horse === 's' ? 'HAZIR BAHİS' : item.horse.name, "\n                </div>\n              </div>");
                    }).join(''), "\n          </div>");
                }).join(''), "\n        </div>\n      "),
                confirm: {
                    sure: {
                        class: 'btn-success',
                        label: 'Onayla',
                        action: function () {
                            playCoupon(false);
                        }
                    },
                    cancel: {
                        label: 'Vazgeç',
                    }
                }
            });
        }
        forceUpdate();
        tjkBetslipTimer = setTimeout(function () {
            tjkBetSlipOptions.errorMessage = null;
            forceUpdate();
        }, 5000);
    };
    // sadece virgüllü bahisler için
    var showRun = tjkBetSlipOptions.hippodrome.runs.find(function (r) { return r.no === tjkBetSlipOptions.bettingSubType; });
    if (isMobile && betSlipToggle) {
        document.body.style.overflow = 'hidden';
    }
    else {
        document.body.style.overflow = 'unset';
    }
    var minBetCount = 0;
    var maxBetCount = 0;
    if (betSlipToggle) {
        return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card tjk-betslip float-left" }, { children: [tjkBetSlipOptions.inProgress && _jsxs("div", __assign({ className: "betslip-overlay" }, { children: [_jsxs("div", __assign({ className: "lds-roller" }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0), _jsx("div", __assign({ className: "text-center mt-3" }, { children: "\u0130\u015Fleminiz devam ediyor." }), void 0)] }), void 0), !isMobile && _jsxs("div", __assign({ className: "card-title" }, { children: [_jsx("h2", { children: tjkBetSlipOptions.bettingType.name }, void 0), _jsx("h3", { children: tjkBetSlipOptions.hippodrome.location }, void 0)] }), void 0), _jsxs("div", __assign({ className: "card-body pl-sm-1 pl-lg-2 pt-2 pb-2 pr-0" }, { children: [_jsx(HorseSortable, { showRun: showRun, itemOrder: itemOrder, onSortEnd: onSortEnd, addFromSelect: addFromSelect }, void 0), _jsx(MultipleColumnHorselist, {}, void 0), _jsx(SingleColumnHorselist, {}, void 0), isMobile && _jsxs("div", __assign({ className: "mobile-betslip-sidebar" }, { children: [_jsxs("h2", { children: [tjkBetSlipOptions.bettingType.name, _jsx("span", { children: tjkBetSlipOptions.hippodrome.location }, void 0)] }, void 0), _jsxs("div", __assign({ className: "actions" }, { children: [!tjkBetSlipOptions.system ? _jsx("div", __assign({ className: "footer-row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("span", __assign({ className: "fw-bold misli-label" }, { children: "Misli:" }), void 0), _jsx("input", { className: "select betslip-input", type: "text", pattern: "^[0-9\\b]+$", min: 1, max: betSlipMaxMultiply, step: 1, value: tjkBetSlipOptions.betSlipMultiply || '', onChange: function (e) { return setTjkBetSlipMultiply(e.target.value); }, onBlur: function () {
                                                                if (tjkBetSlipOptions.betSlipMultiply === '') {
                                                                    setTjkBetSlipMultiply(1);
                                                                }
                                                            }, onPaste: function (e) {
                                                                e.preventDefault();
                                                                return false;
                                                            } }, void 0), _jsx("select", __assign({ className: "betslip-select", value: tjkBetSlipOptions.betSlipMultiply, onChange: function (e) { return setTjkBetSlipMultiply(e.target.value); } }, { children: multiplyChoices.map(function (el, key) {
                                                                return _jsx("option", __assign({ value: el }, { children: el }), key);
                                                            }) }), void 0)] }), void 0) }), void 0) : _jsx(_Fragment, {}, void 0), tjkBetSlipOptions.system ? _jsx("div", __assign({ className: "footer-row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("span", __assign({ className: "fw-bold misli-label" }, { children: "Tutar:" }), void 0), _jsx("input", { className: "select betslip-input", type: "text", pattern: "^[0-9\\b]+$", min: 1, max: betSlipMaxMultiply, step: 1, value: tjkBetSlipOptions.maxAmount || '', onChange: function (e) { return setTjkBetSlipMaxAmount(e.target.value); }, onBlur: function () {
                                                                if (tjkBetSlipOptions.maxAmount === '') {
                                                                    setTjkBetSlipMaxAmount(1);
                                                                }
                                                            }, onPaste: function (e) {
                                                                e.preventDefault();
                                                                return false;
                                                            } }, void 0), _jsx("select", __assign({ className: "betslip-select", value: tjkBetSlipOptions.betSlipMultiply, onChange: function (e) { return setTjkBetSlipMaxAmount(e.target.value); } }, { children: multiplyChoices.map(function (el, key) {
                                                                return _jsx("option", __assign({ value: el }, { children: el }), key);
                                                            }) }), void 0)] }), void 0) }), void 0) : _jsx(_Fragment, {}, void 0), _jsxs("div", __assign({ className: "footer-row" }, { children: [_jsx("div", __assign({ className: "".concat(tjkComplateBettingTypes.includes(tjkBetSlipOptions.bettingType.id) ? 'col-8  pr-1' : 'col-12') }, { children: _jsxs("div", __assign({ className: "select" }, { children: [_jsx("span", __assign({ className: "fw-bold" }, { children: "Bahis:" }), void 0), " ", tjkBetSlipOptions.system ? _jsx(_Fragment, { children: tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ? tjkBetSlipOptions.systemMaxMin[0] : tjkBetSlipOptions.systemMaxMin.join('-') }, void 0) : _jsx(_Fragment, { children: betCount }, void 0)] }), void 0) }), void 0), tjkComplateBettingTypes.includes(tjkBetSlipOptions.bettingType.id) && _jsx("div", __assign({ className: "col-4 pl-0" }, { children: _jsxs("div", __assign({ className: "select pl-1 pr-1 text-center nowrap" }, { children: [_jsx("input", { type: "checkbox", checked: tjkBetSlipOptions.complete, onChange: function () {
                                                                        setTjkComplete(!tjkBetSlipOptions.complete);
                                                                    } }, void 0), " K"] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "footer-row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsxs("div", __assign({ className: "select" }, { children: [_jsx("span", __assign({ className: "fw-bold" }, { children: "Tutar: " }), void 0), tjkBetSlipOptions.system ? _jsx(_Fragment, { children: tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ? _jsxs(_Fragment, { children: [currencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price), "\u20BA"] }, void 0) : _jsxs(_Fragment, { children: [currencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price), "\u20BA - ", currencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price), "\u20BA"] }, void 0) }, void 0) : _jsxs(_Fragment, { children: [currencyFormat(totalAmount), "\u20BA"] }, void 0)] }), void 0) }), void 0) }), void 0), member.id ? _jsx(_Fragment, { children: _jsx("div", __assign({ className: "footer-row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsxs("div", __assign({ className: "select" }, { children: [_jsx("div", __assign({ className: "fw-bold" }, { children: "Puan Kullan:" }), void 0), _jsx("input", { type: "checkbox", checked: tjkBetSlipOptions.usePointBalance, onChange: function () {
                                                                        setTjkUsePointBalance(!tjkBetSlipOptions.usePointBalance);
                                                                    } }, void 0), " ", member ? (member.point_balance / 100).toFixed(2) + '₺' : '0.00₺'] }), void 0) }), void 0) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsxs("div", __assign({ className: "footer-row betslip-footer" }, { children: [tjkBetSlipOptions.errorMessage ? _jsx("div", __assign({ className: "w-100 alert-danger mb-1" }, { children: tjkBetSlipOptions.errorMessage }), void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ className: "w-50" }, { children: _jsx("button", __assign({ onClick: function () {
                                                                confirmCoupon();
                                                            }, disabled: betCount === 0 || (tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount) < (tjkBetSlipOptions.bettingType.id === 18 ? 2 : 0) || (tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount) > 5000 ? true : false, className: "btn btn-primary w-100 m-0" }, { children: "Oyna" }), void 0) }), void 0), _jsx("div", __assign({ className: "w-25 pl-1 pr-1" }, { children: _jsx("button", __assign({ onClick: function () {
                                                                playCoupon(true);
                                                            }, className: "btn  w-100 m-0" }, { children: _jsx("img", { src: Save, width: "16" }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "w-25 pl-1" }, { children: _jsx("button", __assign({ onClick: function () {
                                                                clearTjkBetSlip();
                                                                setItemOrder([]);
                                                            }, className: "btn btn-warning w-100 m-0" }, { children: _jsx("img", { src: Trash, width: "16" }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0)] }), void 0), !isMobile && _jsxs("div", __assign({ className: "card-footer" }, { children: [!tjkBetSlipOptions.system ? _jsx("div", __assign({ className: "footer-row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("span", __assign({ className: "fw-bold misli-label" }, { children: "Misli:" }), void 0), _jsx("input", { className: "select betslip-input", type: "text", pattern: "^[0-9\\b]+$", min: 1, max: betSlipMaxMultiply, step: 1, value: tjkBetSlipOptions.betSlipMultiply || '', onChange: function (e) { return setTjkBetSlipMultiply(e.target.value); }, onBlur: function () {
                                                if (tjkBetSlipOptions.betSlipMultiply === '') {
                                                    setTjkBetSlipMultiply(1);
                                                }
                                            }, onPaste: function (e) {
                                                e.preventDefault();
                                                return false;
                                            } }, void 0), _jsx("select", __assign({ className: "betslip-select", value: tjkBetSlipOptions.betSlipMultiply, onChange: function (e) { return setTjkBetSlipMultiply(e.target.value); } }, { children: multiplyChoices.map(function (el, key) {
                                                return _jsx("option", __assign({ value: el }, { children: el }), key);
                                            }) }), void 0)] }), void 0) }), void 0) : _jsx(_Fragment, {}, void 0), tjkBetSlipOptions.system ? _jsx("div", __assign({ className: "footer-row" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("span", __assign({ className: "fw-bold misli-label" }, { children: "Tutar:" }), void 0), _jsx("input", { className: "select betslip-input", type: "text", pattern: "^[0-9\\b]+$", min: 1, max: betSlipMaxMultiply, step: 1, value: tjkBetSlipOptions.maxAmount || '', onChange: function (e) { return setTjkBetSlipMaxAmount(e.target.value); }, onBlur: function () {
                                                if (tjkBetSlipOptions.maxAmount === '') {
                                                    setTjkBetSlipMaxAmount(1);
                                                }
                                            }, onPaste: function (e) {
                                                e.preventDefault();
                                                return false;
                                            } }, void 0), _jsx("select", __assign({ className: "betslip-select", value: tjkBetSlipOptions.betSlipMultiply, onChange: function (e) { return setTjkBetSlipMaxAmount(e.target.value); } }, { children: multiplyChoices.map(function (el, key) {
                                                return _jsx("option", __assign({ value: el }, { children: el }), key);
                                            }) }), void 0)] }), void 0) }), void 0) : _jsx(_Fragment, {}, void 0), _jsxs("div", __assign({ className: "footer-row" }, { children: [_jsx("div", __assign({ className: "".concat(tjkComplateBettingTypes.includes(tjkBetSlipOptions.bettingType.id) ? 'col-8  pr-1' : 'col-12') }, { children: _jsxs("div", __assign({ className: "select" }, { children: [_jsx("span", __assign({ className: "fw-bold" }, { children: "Bahis:" }), void 0), " ", tjkBetSlipOptions.system ? _jsx(_Fragment, { children: tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ? tjkBetSlipOptions.systemMaxMin[0] : tjkBetSlipOptions.systemMaxMin.join('-') }, void 0) : _jsx(_Fragment, { children: betCount }, void 0)] }), void 0) }), void 0), tjkComplateBettingTypes.includes(tjkBetSlipOptions.bettingType.id) && _jsx("div", __assign({ className: "col-4 pl-0" }, { children: _jsxs("div", __assign({ className: "select pl-1 pr-1 text-center nowrap" }, { children: [_jsx("input", { type: "checkbox", checked: tjkBetSlipOptions.complete, onChange: function () {
                                                        setTjkComplete(!tjkBetSlipOptions.complete);
                                                    } }, void 0), " K"] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "footer-row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsxs("div", __assign({ className: "select" }, { children: [_jsx("span", __assign({ className: "fw-bold" }, { children: "Tutar: " }), void 0), tjkBetSlipOptions.system ? _jsx(_Fragment, { children: tjkBetSlipOptions.systemMaxMin[0] === tjkBetSlipOptions.systemMaxMin[1] ? _jsxs(_Fragment, { children: [currencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price), "\u20BA"] }, void 0) : _jsxs(_Fragment, { children: [currencyFormat(tjkBetSlipOptions.systemMaxMin[0] * tjkBetSlipOptions.bettingType.price), "\u20BA - ", currencyFormat(tjkBetSlipOptions.systemMaxMin[1] * tjkBetSlipOptions.bettingType.price), "\u20BA"] }, void 0) }, void 0) : _jsxs(_Fragment, { children: [currencyFormat(totalAmount), "\u20BA"] }, void 0)] }), void 0) }), void 0) }), void 0), member.id ? _jsx(_Fragment, { children: _jsx("div", __assign({ className: "footer-row" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsxs("div", __assign({ className: "select" }, { children: [_jsx("div", __assign({ className: "fw-bold" }, { children: "Puan Kullan:" }), void 0), _jsx("input", { type: "checkbox", checked: tjkBetSlipOptions.usePointBalance, onChange: function () {
                                                        setTjkUsePointBalance(!tjkBetSlipOptions.usePointBalance);
                                                    } }, void 0), " ", member ? (member.point_balance / 100).toFixed(2) + '₺' : '0.00₺'] }), void 0) }), void 0) }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsxs("div", __assign({ className: "footer-row betslip-footer" }, { children: [tjkBetSlipOptions.errorMessage ? _jsx("div", __assign({ className: "w-100 alert-danger mb-1" }, { children: tjkBetSlipOptions.errorMessage }), void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ className: "w-50" }, { children: _jsx("button", __assign({ onClick: function () {
                                                confirmCoupon();
                                            }, disabled: betCount === 0 || (tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount) < (tjkBetSlipOptions.bettingType.id === 18 ? 2 : 0) || (tjkBetSlipOptions.system ? tjkBetSlipOptions.maxAmount : totalAmount) > 5000 ? true : false, className: "btn btn-primary w-100 m-0" }, { children: "Oyna" }), void 0) }), void 0), _jsx("div", __assign({ className: "w-25 pl-1 pr-1" }, { children: _jsx("button", __assign({ onClick: function () {
                                                playCoupon(true);
                                            }, className: "btn  w-100 m-0" }, { children: _jsx("img", { src: Save, width: "16" }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "w-25 pl-1" }, { children: _jsx("button", __assign({ onClick: function () {
                                                clearTjkBetSlip();
                                                setItemOrder([]);
                                            }, className: "btn btn-warning w-100 m-0" }, { children: _jsx("img", { src: Trash, width: "16" }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }), void 0) }, void 0));
    }
    else {
        return _jsx(_Fragment, {}, void 0);
    }
}
export var SortableItem = SortableElement(function (props) {
    return (_jsxs("li", __assign({ className: "horseSelected" }, { children: [_jsx("span", { children: props.value }, void 0), " ", _jsx("img", { src: Trash, width: 14, onClick: function () {
                    props.addFromSelect(props.value);
                }, className: "reinvert-color cursor-pointer float-right" }, void 0)] }), void 0));
});
export var SortableList = SortableContainer(function (props) {
    return (_jsx("ul", __assign({ className: "horseSelected" }, { children: props.items.map(function (value, index) { return (_jsx(SortableItem, { addFromSelect: props.addFromSelect, index: index, value: value }, "bet-item-".concat(index))); }) }), void 0));
});
