var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { dateTimeShortStringFormat, getDateHumanize, useForceUpdate } from "../../../lib/misc.functions";
import { Event, TrendEvent } from "../components/event";
import { LongTermEvent } from "../components/longterm-event";
import React, { useEffect, useMemo } from "react";
import { myHighLightedMarketGroups } from "../../../services/sportsbook.services";
import { eventMapping } from "../../../store/sportsbook.store";
import { CompetitionLabelHelper } from "../../../definitions/constants/competition-maps";
var page = 0;
export function Eventlist(props) {
    // let visibleSportsBook:any = [];
    var shownSportsBook = props.shownSportsBook;
    var params = props.params;
    var forceUpdate = useForceUpdate();
    var openEventDetail = props.openEventDetail;
    var showEventDetail = props.showEventDetail;
    var prevLabel = '';
    var width = window.innerWidth;
    var getHeader = function (label) {
        // console.log(label);
        var marketGroup = myHighLightedMarketGroups[label.split('__')[2]];
        if (prevLabel != label) {
            prevLabel = label;
            return (_jsxs(React.Fragment, { children: [_jsxs("div", __assign({ className: "groupHeader d-flex justify-content-between align-items-center " + ((params === null || params === void 0 ? void 0 : params.sportSlug) == 'canli' ? 'mb-0' : '') }, { children: [_jsxs("div", __assign({ className: "nowrap d-flex align-items-center" }, { children: [_jsx("div", { className: "icon flex-shrink-0 game-event-icon mr-1 " + label.split('__')[2] }, void 0), _jsx("div", { children: CompetitionLabelHelper(label.split('__')[0]) }, void 0)] }), void 0), _jsx("div", __assign({ className: "float-right" }, { children: label.split('__')[1] }), void 0)] }), void 0), (params === null || params === void 0 ? void 0 : params.sportSlug) == 'canli' &&
                        _jsxs("div", __assign({ className: "mt-0 pb-0 live-sports" }, { children: [_jsx("div", { className: "empty-space", style: { width: showEventDetail ? "0" : "290px" } }, void 0), _jsx("div", __assign({ className: "d-flex w-100 justify-content-around", style: { marginRight: (width < 968 ? '0px' : '25px') } }, { children: marketGroup.map(function (el, key) {
                                        return (_jsx("div", __assign({ className: "group w-100 " + (key === 2 ? 'last-group hidden-detail' : (key === 1 ? 'middle-group' : '')) }, { children: _jsx("div", __assign({ className: "text-center p-0 w-100 fs-small fw-bold" }, { children: el.name }), void 0) }), key));
                                    }) }), void 0), width < 968 && _jsx("div", { style: { width: "50px" } }, void 0)] }), void 0)] }, void 0));
        }
        else {
            return (_jsx(React.Fragment, {}, void 0));
        }
    };
    var vh = window.outerHeight;
    var pageSize = vh / 50;
    // console.log(pageSize)
    useEffect(function () {
        // @ts-ignore
        document.addEventListener('scroll', scrollListener);
        return function () {
            document.removeEventListener('scroll', scrollListener);
        };
    }, []);
    // console.log(page)
    var scrollListener = function () {
        // console.log(shownSportsBook.slice(0,((page+1)*pageSize)).length)
        // @ts-ignore
        var sct = document.body.scrollTop || document.documentElement.scrollTop;
        // console.log(sct, page)
        if (sct > (vh - 100)) {
            if (page < Math.ceil(sct / vh)) {
                page = Math.ceil(sct / vh);
                forceUpdate();
            }
        }
    };
    var date = "";
    var MemoList = function () {
        // console.log("event Listtttttt re render")
        return (_jsx(React.Fragment, { children: shownSportsBook.slice(0, ((page + 1) * pageSize)).map(function (event, key) {
                // console.log("params.eventType",event.eventDate)
                params.eventType != 'uzunvadeli' ? date = getDateHumanize(event.eventDate) : date = dateTimeShortStringFormat(event.eventDate);
                return (_jsxs("div", __assign({ className: "eventObject", id: "eventId_" + event.eventId }, { children: [params.eventType !== 'trend' && getHeader(event.competitionGroupName + ' ' + event.competitionName.replace(event.competitionGroupName, '') + '__' + date + ' ' + event.eventDate.split("T")[1].substring(0, 5) + '__' + event.sportSlug), _jsx("div", __assign({ className: "event-card list  " + (params.eventType === 'trend' ? ' trend-event ' : '') + (event.eventType === '2' ? ' longterm ' : '') + (event.customStyle ? ' highlighted-event ' : '') + ((key %= 2) === 0 ? ' dark ' : '') + (showEventDetail && showEventDetail.eventId === event.eventId ? ' opened ' : '') }, { children: params.eventType !== 'uzunvadeli' && params.sportSlug !== 'motor-sporlar' ?
                                _jsxs(_Fragment, { children: [params.eventType === 'trend' ?
                                            _jsx(TrendEvent, { className: "event-card trend-event", event: event, params: params, eventType: params.eventType, context: openEventDetail }, void 0) :
                                            _jsx(Event, { className: "event-card", event: event, params: params, eventType: params.eventType, context: openEventDetail }, void 0), event.sportSlug === 'duello' && event.eventClosedCombination && event.eventClosedCombination.length > 1 ? _jsxs(_Fragment, { children: [eventMapping[event.eventClosedCombination[0].eventId] && eventMapping[event.eventClosedCombination[0].eventId].isRemoved !== true ? _jsxs(_Fragment, { children: [_jsx("div", { className: "mt-3" }, void 0), event.eventClosedCombination[0] && _jsx(Event, { className: "event-card mt-1", event: eventMapping[event.eventClosedCombination[0].eventId], eventType: params.eventType, isDuelloCombination: true, parentEvent: event, context: openEventDetail }, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0), eventMapping[event.eventClosedCombination[1].eventId] && eventMapping[event.eventClosedCombination[1].eventId].isRemoved !== true ? _jsxs(_Fragment, { children: [_jsx("div", { className: "mt-3" }, void 0), _jsx(Event, { className: "event-card", event: eventMapping[event.eventClosedCombination[1].eventId], eventType: params.eventType, isDuelloCombination: true, parentEvent: event, context: openEventDetail }, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0)] }, void 0) :
                                _jsx(LongTermEvent, { className: "event-card", event: event, eventType: params.eventType, context: openEventDetail }, void 0) }), void 0)] }), "eventId_" + event.eventId));
            }) }, void 0));
    };
    // console.log("params.sportsbookFilter",props.sportsbookFilter)
    var result = useMemo(function () { return MemoList(); }, [page, params.sportSlug, params.eventType, JSON.stringify(props.sportsbookFilter), JSON.stringify(myHighLightedMarketGroups)]);
    return result;
}
