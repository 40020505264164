var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { staticPagesList } from "../../store/static.pages.store";
import { Storage } from "../../lib/localstorege.service";
import AgreementMenu from "./agreementMenu";
import ResponsiblePlay from "../static/responsible.play";
export function Agreements() {
    var params = useParams();
    var _a = useState(null), page = _a[0], setPage = _a[1];
    useEffect(function () {
        initialLeftMenu(false);
        betSlipVisibleSubject.next(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        var staticPagesData = staticPagesList.find(function (x) { return x.slug == params.slug; })
            || Storage.get('staticPagesList').find(function (x) { return x.slug == params.slug; });
        if (staticPagesData) {
            setPage(staticPagesData);
        }
        else {
            setPage(null);
        }
    }, [params]);
    return _jsx("div", __assign({ className: "container-fluid page sosyoley" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "left-column" }, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", { className: "card-title" }, void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsx(AgreementMenu, { slug: params.slug }, void 0) }), void 0)] }), void 0) }), void 0), _jsxs("div", __assign({ className: "right-column" }, { children: [page && _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: page.title }, void 0) }), void 0), _jsx("div", { className: "card-body agreement-body", style: { minHeight: '500px' }, dangerouslySetInnerHTML: { __html: page.content.replaceAll('<p>&nbsp;</p>', '<br/>').replaceAll('\r\n', '').replaceAll('\\n', '') } }, void 0)] }), void 0), params.slug == 'sorumlu-bahis' && _jsx(ResponsiblePlay, {}, void 0)] }), void 0)] }), void 0) }), void 0);
}
