import { Subject } from 'rxjs';
export var sportsbookList = [];
export var eventSliderList = [];
export var eventMapping = {};
export var liveStreamEventList = [];
export var marketMapping = {};
export var marketIdMapping = {};
export var statisticsMap = {};
export var gameDetailData = {};
export var sportsbookFilter = {
    filterCompetition: [],
    filterDays: [],
    filterMBS: [],
    filterLiveStream: false,
    filterLiveEvent: false,
    filterAlarmSet: false,
    filterRapid: false,
    filterCustomRatio: false,
    filterKeyword: '',
};
export var eventUpdateSubject = new Subject();
export var eventDetailSubject = new Subject();
export var sportsSummerySubject = new Subject();
export var customCompetitionPriorty = [];
export var customCompetitionGroupNames = {};
export var sportsSummery = [];
export var popularCompetition = [];
export var highlightedEvents = [];
