import { useState } from "react";
import { env, errorLogStyle, longDays, shortDays, successLogStyle, warninLogStyle } from "../definitions/global.vars";
import { CouponChecker } from "../services/coupon-checker";
import { calculateManually } from "../services/sosyoley.services";
import { scoreStatusIds } from "../definitions/constants/score_status_ids";
import { member } from "../store/login.store";
import { ApiService } from "./http.service";
import { Storage } from "./localstorege.service";
import { sportsSummerySubject } from "../store/sportsbook.store";
import { callLoginApi, refreshMemberInfo } from "../services/login.services";
import { AuthWS, AuthWSProvider, SportbookWS, SportWSProvider } from "../services/websocket.services";
import { modalServiceSubject } from "../components/modal/modal";
var offset = new Date().getTimezoneOffset() / 60;
export function useForceUpdate() {
    // @ts-ignore
    var _a = useState(0), value = _a[0], setValue = _a[1]; // integer state
    return function () { return setValue(function (value) { return value + 1; }); }; // update the state to force render
}
export function GetCookies() {
    var pairs = document.cookie.split(";");
    var cookies = {};
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split("=");
        cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='));
    }
    return cookies;
}
export function setThemeMode(theme) {
    var body = document.getElementsByTagName('body')[0];
    if (body) {
        body.setAttribute('data-theme', theme);
    }
    Storage.set('data-theme', theme);
}
var monthNames = {
    '0': 'Ocak',
    '1': 'Şubat',
    '2': 'Mart',
    '3': 'Nisan',
    '4': 'Mayıs',
    '5': 'Haziran',
    '6': 'Temmuz',
    '7': 'Ağustos',
    '8': 'Eylül',
    '9': 'Ekim',
    '10': 'Kasım',
    '11': 'Aralık',
};
var monthShortNames = {
    '0': 'Ock',
    '1': 'Şub',
    '2': 'Mrt',
    '3': 'Nis',
    '4': 'May',
    '5': 'Haz',
    '6': 'Tem',
    '7': 'Ağu',
    '8': 'Eyl',
    '9': 'Eki',
    '10': 'Kas',
    '11': 'Ara',
};
export function getDateHumanize(eventDate, t) {
    if (t === void 0) { t = 'long'; }
    var now = ServerDate();
    var date1 = new Date(now.toISOString());
    var date2 = new Date(eventDate);
    var Difference_In_Days = date2.getDate() - date1.getDate();
    var day = "";
    if (Difference_In_Days == 0) {
        day = "Bugün";
    }
    else if (Difference_In_Days == 1) {
        day = "Yarın";
    }
    else if (Difference_In_Days > 6) {
        if (t === 'long') {
            day = date2.getDate() + " " + monthNames[String(date2.getMonth())];
        }
        else {
            day = date2.getDate() + " " + monthShortNames[String(date2.getMonth())];
        }
    }
    else {
        if (t === 'long') {
            day = longDays[date2.getDay()];
        }
        else {
            day = shortDays[date2.getDay()];
        }
    }
    return day;
}
export function dateFormat(eventDate) {
    var date = new Date(eventDate);
    date = new Date(date.setTime(date.getTime() - offset));
    return date.toLocaleDateString();
}
export function dateTimeFormat(eventDate) {
    var date = new Date(eventDate);
    date = new Date(date.setTime(date.getTime() - offset));
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString().slice(0, 5);
}
export function dateTimeStringFormat(eventDate, year) {
    if (year === void 0) { year = false; }
    var date = new Date(eventDate);
    date = new Date(date.setTime(date.getTime() - offset));
    return date.getUTCDate() + " " + date.toLocaleString('tr-tr', { month: 'long' }) + (year ? ' ' + date.getFullYear() : '');
}
export function dateTimeShortStringFormat(eventDate) {
    var date = new Date(eventDate);
    date = new Date(date.setTime(date.getTime() - offset));
    return date.getUTCDate() + " " + date.toLocaleString('tr-tr', { month: 'short' });
}
export function calcTimeTurkey() {
    var date = ServerDate();
    var utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    var trDate = new Date(utc + (3600000 * 3));
    return trDate;
}
export function timeFormat(eventDate, l) {
    if (l === void 0) { l = 5; }
    var date = new Date(eventDate);
    date = new Date(date.setTime(date.getTime() - offset));
    return date.toLocaleTimeString().slice(0, l);
}
export function calcMinutesLest(eventDate) {
    var date = new Date(eventDate);
    var now = ServerDate();
    var diff = Math.abs(date) - now;
    var minutes = Math.floor((diff / 1000) / 60);
    return minutes;
}
export function currencyFormat(a) {
    return a.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}
export function currencyFormatWithFix(a, toFixed) {
    if (toFixed === void 0) { toFixed = 2; }
    return a.toFixed(toFixed).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}
export function linkify(plainText) {
    plainText = plainText.replace(/&#x27;/g, "'");
    var replacedText;
    var replacePattern1;
    var replacePattern2;
    replacePattern1 = /(@[0-9a-zA-Z\_ĞÜŞİÖÇğüşıöç.]+)/gim;
    replacedText = plainText.replace(replacePattern1, ' <a href="/sosyoley/profil///$1/tumu">$1</a> ');
    var regex_at = /(\/\/\/@+)/gim;
    replacedText = replacedText.replace(regex_at, '/');
    replacePattern2 = /(#[0-9a-zA-Z\_ĞÜŞİÖÇğüşıöç.]+)/gim;
    replacedText = replacedText.replace(replacePattern2, ' <a href="/sosyoley/arama///$1">$1</a> ');
    var regex_hash = /(\/\/\/#+)/gim;
    replacedText = replacedText.replace(regex_hash, '/');
    return replacedText;
}
export function initialLeftMenu(open) {
    var width = window.innerWidth;
    // sportsMenu.isVisible = open;
    sportsMenu.isVisible = innerWidth < 968 ? false : open;
    if (width < 968) {
        var leftSideMenu = document.getElementById('leftSideMenu');
        if (leftSideMenu) {
            if (!sportsMenu.isVisible) {
                leftSideMenu.style.display = 'none';
                leftSideMenu.style.width = '100%';
            }
            else {
                leftSideMenu.style.display = 'block';
                leftSideMenu.style.width = '50px';
            }
        }
        var sportsListToggler = document.getElementById('sportsList');
        if (sportsListToggler) {
            sportsListToggler.style.left = sportsMenu.isVisible ? '0px' : '-300px';
        }
        var mobileNavMenu = document.getElementById('mobileNavMenu');
        if (mobileNavMenu) {
            mobileNavMenu.style.left = '-300px';
        }
    }
}
export var sportsMenu = { isVisible: false };
export function getCouponStatus(event, market, outcome) {
    var iconStatus;
    var oleyOutcomeResult;
    var shortName = 'MS';
    var eventStatus;
    var outcomeResult = outcome.outcomeResult;
    if (Number(outcomeResult) === 1) {
        if (event.scores) {
            var eventStatus_1 = CouponChecker(event, market, outcome);
            // console.log("eventStatus",eventStatus)
            iconStatus = eventStatus_1;
            if ([2, 3, 8].includes(eventStatus_1)) {
                oleyOutcomeResult = 2; // Win
            }
            else if ([4, 5, 9].includes(eventStatus_1)) {
                oleyOutcomeResult = 0; // Lose
            }
        }
        else {
            var manualData = calculateManually(event.eventDate);
            // console.log("manualData",manualData)
            iconStatus = manualData.iconStatus;
            shortName = manualData.shortName;
        }
    }
    else {
        var manualData = calculateManually(event.eventDate);
        // console.log("manualData",manualData)
        iconStatus = manualData.iconStatus;
        var eventStatus_2;
        if (Number(outcomeResult) === 0) {
            // Resmi kaybetti
            eventStatus_2 = 9;
            iconStatus = eventStatus_2;
        }
        else if (Number(outcomeResult) === 2) {
            // Resmi kazandı
            eventStatus_2 = 8;
            iconStatus = eventStatus_2;
        }
        else if (Number(outcomeResult) === 3) {
            // Resmi ertelenme
            eventStatus_2 = 7;
            iconStatus = eventStatus_2;
            shortName = "Ert.";
        }
        else if (Number(outcomeResult) === 4) {
            // Resmi iptal
            eventStatus_2 = 7;
            iconStatus = eventStatus_2;
            shortName = "İpt.";
        }
    }
    if (event.scores) {
        shortName = (scoreStatusIds.filter(function (el) { return el.id === Number(event.scores.statusId); }).length > 0) ? (scoreStatusIds.filter(function (el) { return el.id === Number(event.scores.statusId); }))[0].shortName : '';
    }
    return { oleyOutcomeResult: oleyOutcomeResult, eventStatus: eventStatus, iconStatus: iconStatus, shortName: shortName };
}
export function toggleMobileSideMenu() {
    var sportsList = document.getElementById('sportsList');
    var mobileNavMenu = document.getElementById('mobileNavMenu');
    var leftSideMenu = document.getElementById('leftSideMenu');
    var isOpened;
    if ((mobileNavMenu && mobileNavMenu.offsetLeft === -300) || (leftSideMenu && leftSideMenu.offsetWidth <= 50)) {
        isOpened = false;
    }
    else {
        isOpened = true;
    }
    if (mobileNavMenu && sportsList && leftSideMenu) {
        if (isOpened) {
            mobileNavMenu.style.left = '-300px';
            if (!sportsMenu.isVisible) {
                sportsList.style.left = '-300px';
            }
            setTimeout(function () {
                if (sportsMenu.isVisible) {
                    leftSideMenu.style.width = '50px';
                }
                else {
                    leftSideMenu.style.width = '100%';
                }
            }, 100);
            setTimeout(function () {
                if (sportsMenu.isVisible) {
                    leftSideMenu.style.display = 'block';
                }
                else {
                    leftSideMenu.style.display = 'none';
                }
            }, 300);
            // console.log("close menu")
        }
        else {
            // console.log("open menu")
            if (leftSideMenu) {
                leftSideMenu.style.display = 'block';
                leftSideMenu.style.width = '100%';
            }
            setTimeout(function () {
                mobileNavMenu.style.left = '0px';
            }, 100);
            setTimeout(function () {
                sportsList.style.left = '0px';
            }, 100);
        }
    }
}
export function toggleCompetitionList(navigate) {
    if (window.location.pathname === '/' ||
        window.location.pathname.includes('sosyoley') ||
        window.location.pathname.includes('canli-skorlar') ||
        window.location.pathname.includes('hesabim') ||
        window.location.pathname.includes('duyurular') ||
        window.location.pathname.includes('giris-yap') ||
        window.location.pathname.includes('kayit') ||
        window.location.pathname.includes('bildirimler') ||
        window.location.pathname.includes('spor-toto')) {
        navigate('/iddaa/futbol/tumu');
    }
    else {
        var compititationLeftMenu = document.getElementById("compititationLeftMenu");
        var sportsBookWrapper = document.getElementById("sportsBookWrapper");
        window.scrollTo(0, window.scrollY - 1);
        if (compititationLeftMenu && sportsBookWrapper) {
            var isVisible = getComputedStyle(compititationLeftMenu, null).display === 'block' ? true : false;
            if (isVisible) {
                compititationLeftMenu.style.display = 'none';
                sportsBookWrapper.classList.add('expand');
            }
            else {
                compititationLeftMenu.style.display = 'block';
                sportsBookWrapper.classList.remove('expand');
            }
        }
    }
}
export var ServerTimeDifference = 0;
export function getServerTime() {
    var browserDate = new Date();
    var apiService = new ApiService();
    apiService.start('get', 'https://sportsbook.oley.com/get-current-date/?v=' + Math.random().toString(), null, false)
        .then(function (response) {
        if (response.status) {
            var serverDate = new Date(response.data.currentDate);
            // @ts-ignore
            ServerTimeDifference = (serverDate.getTime() + (serverDate.getTimezoneOffset() * 60000)) - browserDate.getTime() + (3600000 * 3);
            console.log('%cSunucu ve Tarayıcı zaman farkı: ' + ServerTimeDifference + ' saniye', warninLogStyle);
            console.log("%cTarayıcı Zamanı: " + browserDate, warninLogStyle);
            console.log("%cSunucu Zamanı: " + new Date(response.data.currentDate), warninLogStyle);
            // console.log("New date time",new Date(browserDate.getTime() + ServerTimeDifference));
        }
    }).catch(function (e) {
        console.log(e);
    });
}
getServerTime();
export function ServerDate() {
    return new Date(new Date().getTime() + ServerTimeDifference);
}
function listenIframe(event) {
    if (event.data.type == 'close') {
        document.body.style.overflow = 'unset';
        var luckySpiner = document.getElementById('luckySpiner');
        luckySpiner.remove();
        window.removeEventListener("message", listenIframe);
    }
    else if (event.data.type == "turn-and-close") {
        member.game_token = null;
        sportsSummerySubject.next(true);
        refreshMemberInfo();
    }
}
export function showLuckySpinModal() {
    var lucky_spin_url = 'https://wheel.oley.com/wheel-game/?token=' + member.game_token;
    var rootDiv = document.getElementById('root');
    if (rootDiv) {
        var luckySpiner_1 = document.createElement("iframe");
        luckySpiner_1.classList.add("luckySpiner");
        luckySpiner_1.src = lucky_spin_url;
        luckySpiner_1.id = "luckySpiner";
        rootDiv.append(luckySpiner_1);
        document.body.style.overflow = 'hidden';
        setTimeout(function () {
            if (luckySpiner_1) {
                window.addEventListener('message', listenIframe);
            }
        }, 1000);
    }
}
export function navMenuSelector(e) {
    var navMenu = document.getElementsByTagName('nav');
    if (navMenu) {
        for (var _i = 0, navMenu_1 = navMenu; _i < navMenu_1.length; _i++) {
            var nav = navMenu_1[_i];
            var items = nav.getElementsByClassName('active');
            if (items) {
                for (var _a = 0, items_1 = items; _a < items_1.length; _a++) {
                    var item = items_1[_a];
                    item.classList.remove('active');
                }
            }
        }
    }
    try {
        e.target.parentElement.parentElement.classList.add('active');
        e.target.parentElement.style.display = 'none';
        setTimeout(function () {
            e.target.parentElement.style.display = null;
        }, 100);
    }
    catch (e) {
    }
    try {
        e.target.classList.add('active');
    }
    catch (e) {
    }
}
export function isOnline() {
    console.log("%c Internet connected up again :)", successLogStyle);
}
export function isOffline() {
    console.log("%c Ops, Connection lost ! :(", errorLogStyle);
    if (AuthWSProvider && AuthWSProvider.readyState === 1) {
        AuthWS.ws.close();
    }
    if (SportWSProvider && SportWSProvider.readyState === 1) {
        SportbookWS.ws.close();
    }
}
export function showAgreementModalForLogin(username, password, agreements, approvedItems) {
    modalServiceSubject.next({
        title: agreements[0].title,
        width: '700px',
        height: 'calc(70vh - 96px)',
        content: agreements[0].content,
        confirm: {
            sure: {
                label: 'Onaylıyorum',
                action: function () {
                    approvedItems.push(agreements[0].type);
                    agreements.shift();
                    if (agreements.length > 0) {
                        setTimeout(function () {
                            showAgreementModalForLogin(username, password, agreements, approvedItems);
                        }, 500);
                    }
                    else {
                        callLoginApi(username, password, { approved_agreement: approvedItems.join(',') });
                    }
                }
            },
            cancel: {
                label: 'Reddediyorum',
                action: function () {
                }
            }
        },
        timeout: 0
    });
}
export function showAgreementModalWss(agreements, approvedItems) {
    setTimeout(function () {
        modalServiceSubject.next({
            title: agreements[0].title,
            width: '700px',
            height: 'calc(70vh - 96px)',
            content: agreements[0].content,
            confirm: {
                sure: {
                    label: 'Onaylıyorum',
                    action: function () {
                        approvedItems.push(agreements[0].type);
                        agreements.shift();
                        if (agreements.length > 0) {
                            setTimeout(function () {
                                showAgreementModalWss(agreements, approvedItems);
                            }, 500);
                        }
                        else {
                            var apiService = new ApiService();
                            var updateData = {
                                approved_agreement: approvedItems.join(','),
                                token: Storage.get('token')
                            };
                            apiService.start('post', env.accounting_api + '/member/api/approve-agreement/', updateData, true)
                                .then(function (response) {
                                if (response.status) {
                                }
                            }).catch(function (e) {
                                console.log(e);
                            });
                        }
                    }
                },
                cancel: {
                    label: 'Reddediyorum',
                    action: function () {
                    }
                }
            },
            timeout: 0
        });
    }, 1000);
}
export function slugify(str) {
    str = str.toLowerCase().replaceAll('ü', "u").replaceAll('ğ', "g").replaceAll('ı', "i").replaceAll('ş', "s").replaceAll('ç', "c");
    str = str.replace(/^\s+|\s+$/g, ""); // trim leading/trailing white space
    str = str.toLowerCase();
    str = str
        .replace(/[^a-z0-9 -]/g, "") // remove any non-alphanumeric characters
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/-+/g, "-"); // remove consecutive hyphens
    return str;
}
